import {} from '../css/main.scss';
import '../js/lazysizes';
import "../js/bootstrap";
import "../js/fontawesome";
import "../js/magnificpopup";

import ExternalLinks  from "./externallinks";
import PageTimer from "./pagetimer";


(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();

let popups = document.querySelectorAll('[data-mfp-type]');
if(popups.length) {
    new Promise((resolve) => {
        import(/* webpackChunkName: "magnific" */ '../js/magnificpopup')
            .then((Magnific) => {
                let magnific =  new Magnific.default(popups);
                resolve(magnific.initialize())
            })
    })
}

let forms = document.getElementsByClassName('needs-validation');
if(forms.length) {
    new Promise((resolve) => {
        import(/* webpackChunkName: "forms" */ '../js/form')
            .then((Forms) => {
                let form =  new Forms.default(forms);
                resolve(form.submitButton().validate())
            })
    })
}


let shares = document.querySelectorAll('[data-share-default]');
if(shares.length) {
    new Promise((resolve) => {
        import(/* webpackChunkName: "share" */ '../js/share')
            .then((ShareHandler) => {
                let socialShare =  new ShareHandler.default();
                resolve(socialShare.initialize())
            })
    })
}


$(window).on('load', function(){
    // Delay glide initialisation 
    // - to prevent gap between slides
    let glides = document.getElementsByClassName('glide');
    if (glides.length) {
        new Promise((resolve) => {
            import(/* webpackChunkName: "glider" */ '../js/glide').
                then((Glider) => {
                    let glide = new Glider.default(glides);
                    resolve(glide.initialize());
                });
        })
    }

    // Scroll to top 
    $('.scroll-top').hide();
    $(window).on('scroll',function () {
        if ($(this).scrollTop() > 100) {
            $('.scroll-top').fadeIn();
        } else {
            $('.scroll-top').fadeOut();
        }
    });
    
    $('.scroll-top').on('click',function () {
        $('html, body').animate({
            scrollTop: 0
        }, 300);
            return false;
    });
    // End scroll to top 

    // Navigation indicator that follows mouse
    var $el, leftPos, newWidth;

    // Add indicator to navigation
    $(".navbar-nav").append("<li id='magic-line' class='position-absolute magic-line'></li>");

    // Create indicator variable
    var $magicLine = $("#magic-line");

    // Set initial poaition and widths
    // Setting width and left pos to less than nav item - styling purposes
    $magicLine
        .width($(".navbar-nav .nav-item.active").width() - 30)
        .css("left", $(".navbar-nav .nav-item.active .nav-link").position().left + 15)
        .data("origLeft", $magicLine.position().left)
        .data("origWidth", $magicLine.width());

    // get position of cursor and set 
    // indicator's position to this nav item 
    $(".navbar-nav .nav-item")
        .find(".nav-link")
        .hover(
        function() {
            $el = $(this);
            leftPos = $el.position().left + 15;
            newWidth = $el.parent().width() - 30;

            $magicLine.stop().animate({
                left: leftPos,
                width: newWidth
            });
        },
        function() {
            $magicLine.stop().animate({
                left: $magicLine.data("origLeft"),
                width: $magicLine.data("origWidth")
            });
        }
    );

    // get query param - specifically used for contact us
    var param = document.URL.split('?q=')[1];
    console.log(param);
    var email = '';
    if (document.getElementById('fields-selectEmail-field')) {
        if (param == 'support') {
            // console.log('true');
            var email = 'support';
        } else {
            var email = 'info';
        }
        $('#fields-selectEmail-field select').val(email);
        $('#fields-selectEmail-field select').refresh();
    }
});    

// Add active class to opened element - styling purposes
$('[data-type="accordion"]').each(function(){
    $(this).find('.card:first').addClass('active');
    $(this).find('button').on('click', function(){
        $('[data-type="accordion"] .card').removeClass('active');
        $(this).parents('.card').addClass('active');
    });
});

// Slide panel slide out (contact form)
// $('.btn-slide-out').on('click', function(){
//     $('.slide-out').toggleClass('closed open');
// })




// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}